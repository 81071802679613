import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const userRoleDataService = async (userEmail) => {
  const userRoleData = await axiosInstance.get(
    `users/edit/getuser/${userEmail}`
  );
  return userRoleData;
};

export const authDataService = async (values) => {
  const authData = await axiosInstance
    .post("/authenticate", values)
    .then((res) => res.data);
  return authData;
};
