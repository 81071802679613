import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { viewRolesdtd, transferDept } from "./viewRoleApis";
import "../css/rule.css";
import { TestConnectionDBService } from "../../API Services/TestConnectionService";
import {
  fetchVersionDataApidtd,
  migrateJobdtd,
  migrateJobValidation,
  reuseVersionApidtd,
} from "../../API Services/RulesService/dtdServices";
import EncryptDataComponent from "../../Encrypt API/Encrypt";

const EditDatabaseToDb = () => {
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const { handleEncryptedRequest } = EncryptDataComponent();

  //added for limit query.
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { ruleId, viewRole } = location.state || {};
  // const navigate = useNavigate();
  const toast = useRef(null);
  const { setOpenDialog, setcontextFormData, setSelectedViewAs } = useContext(
    ContextTimeScheduling
  );

  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");
  const { response } = location.state || {};
  const [objectData, setObjectData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [codeStatus, setCodeStatus] = useState("");

  const [formData, setFormData] = useState({
    jobname: "",
    sourceSecretName: "",
    sourceDbUrl: "",
    sourceTable: "",
    sourceDbUser: "",
    sourceDbPassword: "",
    queryText: "",
    addQuery: false,
    targetSecretName: "",
    targetTable: "",
    targetDbUrl: "",
    targetDbUser: "",
    targetDbPassword: "",
    //////////////////////////
    isInsertQuery: false,
    insertQueryText: "",
    jiraTicketNumber: "",
    version: "",
    versions: [],
  });

  useEffect(() => {
    if (ruleId === undefined) navigate("/home");
    setSelectedViewAs(viewRole);
    const fetchData = async () => {
      try {
        // // console.log("This is the rule Id :" + ruleId);
        // const role = getRole()
        const axiosInstance = await myAxios();
        if (viewRole === "DEV") {
          const response = await axiosInstance.get(
            `${viewRolesdtd[0].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "QA") {
          const response = await axiosInstance.get(
            `${viewRolesdtd[1].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "PROD") {
          const response = await axiosInstance.get(
            `${viewRolesdtd[2].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
        let errorValue = error.response ? error.response : null;
        console.log(errorValue);
        if (errorValue == null) {
          navigate("/login");
        } else if (errorValue.status === 401) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Token Expired ",
            detail: "Session Time Out ",
            life: 2000,
          });
        } else if (errorValue.status === 403) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "Try Login Again.",
            life: 2000,
          });
        }

        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  //Dropdown logic
  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        sourceDbUrl: environmentData?.dburl || "",
        sourceDbUser: environmentData?.username || "",
        sourceDbPassword: environmentData?.password || "",
      }));
    }
  };

  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData2["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData2["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData2["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName2.name || "",
        targetDbUrl: environmentData?.dburl || "",
        targetDbUser: environmentData?.username || "",
        targetDbPassword: environmentData?.password || "",
      }));
    }
  };

  // this function is used to set the data .
  // setSelectedSftpOption(selectedName);
  const setServerData = (secretName) => {
    response?.map((resp) => {
      if (resp.name === secretName) {
        setSelectedSftpOption(resp);
        // console.log(resp)
      }
    });
  };

  const setServerData2 = (secretName2) => {
    response?.map((resp) => {
      if (resp.name === secretName2) {
        setSelectedSftpOption2(resp);
        // console.log(resp)
      }
    });
  };

  useEffect(() => {
    if (ruleData) {
      // console.log(ruleData, " contains all the data ");
      // const cronValues = parseCronExpression(ruleData.cronExpression);
      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";

      // Extract time part from startDate
      setServerData(ruleData?.sourceSecretName);
      setServerData2(ruleData?.targetSecretName);

      setFormData({
        sourceSecretName: ruleData.sourceSecretName || "",
        jobname: ruleData.jobname || "",
        sourceDbUrl: ruleData.sourceDbUrl || "",
        sourceTable: ruleData.sourceTable || "",
        sourceDbUser: ruleData.sourceDbUser || "",
        sourceDbPassword: ruleData.sourceDbPassword || "",
        archiveLocation: ruleData.archiveLocation || "",
        queryText: ruleData.queryText || "",
        insertQueryText: ruleData.insertQueryText || "",
        targetSecretName: ruleData.targetSecretName || "",
        targetTable: ruleData.targetTable || "",
        targetDbUrl: ruleData.targetDbUrl || "",
        targetDbUser: ruleData.targetDbUser || "",
        targetDbPassword: ruleData.targetDbPassword || "",
        // ...cronValues,
        minutes: ruleData.minutes || "",
        hours: ruleData.hours || "",
        months: ruleData.months || "",
        days: ruleData.days || "",
        weeks: ruleData.weeks || "",
        frequencyType: ruleData.frequencyType || "",

        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "",
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        addQuery: !!ruleData.queryText,
        isInsertQuery: !!ruleData.insertQueryText,
        jiraTicketNumber: ruleData.jiraTicketNumber || "",
        version: ruleData.version || "",
        versions: ruleData.versions || "",
      });
    }
  }, [ruleData]);

  /////////////////////////////////////////////////////////////////////
  // added for the database connection
  const [databaseConnectionTest, setDatabaseConnectionTest] = useState({
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    tableName: "",
  });

  useEffect(() => {
    if (databaseConnectionTest) {
      if (checkDatabaseConnectionValid()) {
        setLoading(true);
        setVisible(true);
        callTestDatabase();
      }
    }
  }, [databaseConnectionTest]);

  const checkDatabaseConnectionValid = () => {
    for (const key in databaseConnectionTest) {
      if (databaseConnectionTest[key] === "") {
        return false;
      }
    }

    return true;
  };

  // handle the database  ApI call here
  const callTestDatabase = async () => {
    try {
      // const axiosInstance = await myAxios();
      // let sftp_connect = await axiosInstance
      //   .post("/test/con/db", databaseConnectionTest)
      //   .then((res) => res.data)
      //   .catch((e) => e.response.data);
      // console.log(sftp_connect);

      const encryptedData = await handleEncryptedRequest(
        databaseConnectionTest
      );

      let sftp_connect = await TestConnectionDBService(encryptedData);
      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      // update code added here for message and status
      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response?.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestForDBConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.sourceDbPassword.trim() === "" ||
        formData.sourceDbUser.trim() === "" ||
        formData.sourceDbUrl.trim() === "" ||
        formData.sourceTable.trim() === ""
      ) {
        // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's Database required fields  .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.sourceDbUrl,
          dbUser: formData.sourceDbUser,
          dbPass: formData.sourceDbPassword,
          tableName: formData.sourceTable,
        });
      }
    }

    if (buttonType === 2) {
      if (
        formData.targetDbPassword.trim() === "" ||
        formData.targetDbUser.trim() === "" ||
        formData.targetDbUrl.trim() === "" ||
        formData.targetTable.trim() === ""
      ) {
        // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's Database required fields .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.targetDbUrl,
          dbUser: formData.targetDbUser,
          dbPass: formData.targetDbPassword,
          tableName: formData.targetTable,
          jiraTicketNumber: formData.jiraTicketNumber,
        });
      }
    }
  };

  //////////////////////////////////////////////////////////////////////

  const validateForm = () => {
    // console.log(
    //   formData.addQuery,
    //   formData.queryText,
    //   " addquery and insert query "
    // );
    // console.log(formData, " FORMDATA ");
    if (
      formData.addQuery &&
      (formData.queryText === undefined || formData.queryText === "")
    ) {
      return false;
    }
    if (
      formData.isInsertQuery &&
      (formData.insertQueryText === undefined ||
        formData.insertQueryText === "")
    ) {
      return false;
    }

    const listofValidateFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "sourceTable",
      "targetTable",
    ];

    for (let e of listofValidateFields) {
      if (formData[e] === "") {
        // console.log(e, " name of fields");
        return false;
      }
    }

    return true; // All fields are filled
  };

  const handleClick = (e) => {
    e.preventDefault();

    // adding for limit query error
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    // console.log(validateForm(), "form is valid or not ");
    if (!validateForm()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all required fields.",
      });
    } else {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    }
  };

  ////////////////////////////////////////////////////

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
        // console.log(
        //   "can't go more than 1 lakh records try to break down in smaller chunk with the limit and offset value "
        // );
      } else {
        // console.log("Limit value:", limitValue); // Output: 10
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      // console.log("No LIMIT clause found");
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      // Reset queryText if addQuery is unchecked
      setFormData({ ...formData, [name]: val, queryText: "" });
    } else if (name === "isInsertQuery" && !val) {
      setFormData({ ...formData, [name]: val, insertQueryText: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  // const for the data migration status role
  const [selectedTransferDept, setSelectedTransferDept] = useState("");

  // const { getRole } = useUserContext();

  const filteredOptions = transferDept.filter((option) => {
    if (viewRole === "DEV") return option.code === "qa";
    if (viewRole === "QA") return option.code === "prod";
    return true; // Show all options for admin or other roles
  });

  const accept = async () => {
    setLoading(true);
    try {
      // const axiosInstance = await myAxios();
      // await axiosInstance
      //   .post(
      //     `/moveto${selectedTransferDept.name.toLowerCase()}/dtd`,
      //     objectData?.data
      //   )
      const encryptedData = await handleEncryptedRequest(objectData);

      await migrateJobdtd(selectedTransferDept, encryptedData)
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successfuly",
            detail: `Job Migrated to ${selectedTransferDept.name}`,
            life: 3000,
          });
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: `Error While Migration`,
            detail: `${e.response.data}`,
            life: 3000,
          });
        });
      setSelectedTransferDept("");
      setconfirmDialogVisible(false);
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error while migrating to ${selectedTransferDept.name}`,
        life: 3000,
      });
      setSelectedTransferDept("");
    } finally {
      setLoading(false);
    }
  };

  const reject = () => {
    setSelectedTransferDept("");
    setconfirmDialogVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Request Cancalled",
      life: 3000,
    });
    setObjectData({});
    setErrorMessage("");
    setCodeStatus("");
  };
  const [confirmDialogVisible, setconfirmDialogVisible] = useState(false);

  const jobMigrate = async () => {
    // const axiosInstance = await myAxios();

    setLoading(true);
    try {
      // const axiosInstance = await myAxios();
      // const response = await axiosInstance.post(
      //   `/moveto${filteredOptions[0].code}validation?id=${ruleId}&move="moving"&approve="pending"`
      // );
      const response = await migrateJobValidation(filteredOptions, ruleId);
      setObjectData(response?.data);
      setconfirmDialogVisible(true);
      console.log(response.data);
    } catch (err) {
      console.log(err);
      if (err.response?.data?.status === 404) {
        setErrorMessage(err.response?.data?.messages);
        setCodeStatus(err.response?.data?.status);
        setconfirmDialogVisible(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An Error Occured",
          life: 3000,
        });
      }
      setSelectedTransferDept("");
    } finally {
      setLoading(false);
    }
  };

  // this is for the version control
  const [isCurrentVersion, setIsCurrentVersion] = useState(true);
  const versionOptions = Array.from(formData.versions);
  const [selectedVersion, setSelectedVersion] = useState("");
  const fetchVersionData = async (e) => {
    setSelectedVersion(e.value);
    // const axiosInstance = await myAxios();
    try {
      // let data;
      // if (viewRole === "DEV") {
      //   const resp = await axiosInstance.get(
      //     `dtd/sch/getVersion?ruleId=${formData.ruleId}&version=${e.value}`
      //   );
      //   data = resp.data;
      // } else {
      //   const resp = await axiosInstance.get(
      //     `${viewRole.toLowerCase()}/dtd/sch/getVersion?ruleId=${
      //       formData.ruleId
      //     }&version=${e.value}`
      //   );
      //   data = resp.data;
      //   console.log("this i smount data", data);
      // }
      const data = await fetchVersionDataApidtd(viewRole, formData, e.value);
      setFormData((prevState) => ({
        ...prevState,
        ...data,
      }));
    } catch (error) {
      console.log("error while getting version data");
    }
  };
  //showing the versino
  useEffect(() => {
    if (formData.version) {
      setSelectedVersion(formData.version);

      console.log("is current version", formData.version === ruleData.version);

      if (formData.version !== ruleData.version) {
        setIsCurrentVersion(false);
      } else {
        setIsCurrentVersion(true);
      }
    }
  }, [formData.version]);

  const handleReuseVersion = async (e) => {
    e.preventDefault();

    // const axiosInstance = await myAxios();
    try {
      // if (viewRole === "DEV") {
      //   await axiosInstance.post(`dtd/sch/setVersion`, formData);
      // } else {
      //   await axiosInstance.post(
      //     `${viewRole.toLowerCase()}/dtd/sch/setVersion`,
      //     formData
      //   );
      // }

      const encryptedData = await handleEncryptedRequest(formData);

      await reuseVersionApidtd(viewRole, encryptedData);
      // console.log("thi is the form data", resp);
      toast.current.show({
        severity: "success",
        summary: `Version ${formData?.version} is in Use..`,
        detail: "Current Version Changed",
        life: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: `Faild to RoleBack the Version`,
        detail: "Version Rollback Failed",
        life: 2000,
      });
    }
  };

  const dataMigrate = () => {
    return (
      <div>
        <div className="col-12 sm:col-11">
          <div className="flex-auto">
            <label
              htmlFor="jobname"
              className="font-semibold text-xs block mx-1 mb-1"
            >
              Job Name
            </label>
            <InputText
              id="jobname"
              name="jobname"
              className="w-full no-highlight"
              value={objectData?.data?.jobname}
              readOnly
              style={{
                outline: "none" /* Removes the default outline */,
                boxshadow: "none",
                backgroundColor: "#dcdcdc",
                border: "none",
                color: "#000",
                fontFamily: "Courier New",
                overflow: "auto", // Allows scrolling
                whiteSpace: "nowrap", // Prevents wrapping
              }}
            />
          </div>
        </div>

        <div className="grid col-12">
          <div className="col-6">
            <div className="grid">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Source</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Source Database Details
                  </label>
                  <InputText
                    id="jobname"
                    name="jobname"
                    className="w-full no-highlight"
                    value={objectData?.data?.sourceSecretName}
                    readOnly
                    style={{
                      outline: "none" /* Removes the default outline */,
                      boxshadow: "none",
                      backgroundColor: "#dcdcdc",
                      border: "none",
                      color: "#000",
                      fontFamily: "Courier New",
                      overflow: "auto", // Allows scrolling
                      whiteSpace: "nowrap", // Prevents wrapping
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="tableName_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Source Table Name
                  </label>
                  <InputText
                    id="tableName_input"
                    name="sourceTable"
                    className="w-full no-highlight"
                    value={objectData?.data?.sourceTable}
                    readOnly
                    style={{
                      outline: "none" /* Removes the default outline */,
                      boxshadow: "none",
                      backgroundColor: "#dcdcdc",
                      border: "none",
                      color: "#000",
                      fontFamily: "Courier New",
                      overflow: "auto", // Allows scrolling
                      whiteSpace: "nowrap", // Prevents wrapping
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="grid w-full">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Target</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Target Database Details
                  </label>
                  <InputText
                    id="jobname"
                    name="jobname"
                    className="w-full no-highlight"
                    value={objectData?.data?.targetSecretName}
                    readOnly
                    style={{
                      outline: "none" /* Removes the default outline */,
                      boxshadow: "none",
                      backgroundColor: "#dcdcdc",
                      border: "none",
                      color: "#000",
                      fontFamily: "Courier New",
                      overflow: "auto", // Allows scrolling
                      whiteSpace: "nowrap", // Prevents wrapping
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="targetTable"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Target Table Name
                  </label>
                  <InputText
                    id="targetTable"
                    name="targetTable"
                    className="w-full no-highlight"
                    value={objectData?.data?.targetTable}
                    readOnly
                    style={{
                      outline: "none" /* Removes the default outline */,
                      boxshadow: "none",
                      backgroundColor: "#dcdcdc",
                      border: "none",
                      color: "#000",
                      fontFamily: "Courier New",
                      overflow: "auto", // Allows scrolling
                      whiteSpace: "nowrap", // Prevents wrapping
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {objectData.message !== null && objectData.message !== "" && (
          <div className="flex">
            <span className="font-bold text-lg">Note :</span>
            <span className="ml-1 text-lg"> {objectData?.message}</span>
          </div>
        )}
      </div>
    );
  };

  const footerConponent = (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="3"
          />
        </div>
      )}
      <Button
        label="Proceed"
        icon="pi pi-check"
        onClick={accept}
        autoFocus
        disabled={loading}
      />
    </div>
  );

  return (
    <>
      <div style={{ marginLeft: "25px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <div className="nameAndPush">
                <span className="text-2xl font-bold">
                  Database to Database Transfer
                </span>
                {loading && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                      pointerEvents: "none",
                    }}
                  >
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="3"
                    />
                  </div>
                )}
                <Dialog
                  header={errorMessage ? "Information" : "Confirmation"}
                  visible={confirmDialogVisible}
                  style={{ width: "40vw" }}
                  onHide={reject}
                  footer={!errorMessage ? footerConponent : null}
                  draggable={false}
                  resizable={false}
                >
                  {errorMessage ? (
                    <div className="text-center">
                      <h3>Status : {codeStatus}</h3>
                      <h3>{errorMessage}</h3>
                    </div>
                  ) : (
                    <>
                      <h3 className="ml-3 -mt-1">
                        <i
                          className="pi pi-exclamation-triangle mr-2"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        Are you sure you want to proceed with{" "}
                        {selectedTransferDept.name}?
                      </h3>
                      {dataMigrate()}
                    </>
                  )}
                </Dialog>

                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {viewRole !== "PROD" && (
                    <div>
                      <Dropdown
                        value={selectedTransferDept}
                        onChange={async (e) => {
                          setSelectedTransferDept(e.value);
                          await jobMigrate();

                          // setconfirmDialogVisible(true);
                        }}
                        options={filteredOptions}
                        optionLabel="name"
                        placeholder="Migrate To:"
                        className="w-full migrateDrop"
                      />
                    </div>
                  )}
                  <div>
                    <Dropdown
                      value={selectedVersion}
                      onChange={(e) => {
                        fetchVersionData(e);
                      }}
                      options={versionOptions} // Directly pass the array of strings
                      placeholder="Version:"
                      className="w-full versionDrop"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select Database Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source Database"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="sourceTable"
                      className="w-full"
                      value={formData.sourceTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.addQuery && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 1)}
                      className="border-round-lg w-full"
                      disabled={loading}
                    />
                  </div>
                </div>

                {/* <div className="col-12"></div> */}
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select Database Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null}
                      onChange={handleSftpSelectChange2}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target Database"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="targetTable"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full"
                      value={formData.targetTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isInsertQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="isInsertQuery"
                      name="isInsertQuery"
                      checked={formData.isInsertQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.isInsertQuery && (
                    <div className="group">
                      <InputTextarea
                        name="insertQueryText"
                        placeholder="Enter SQL query here(insert query)"
                        className="w-full"
                        autoResize
                        value={formData.insertQueryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 2)}
                      className="border-round-lg w-full"
                      disabled={loading}
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {" "}
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>

                <div className="col-12"></div>
              </div>
            </div>

            <div className="col-4"></div>
            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
              <Button
                label="UseVersion"
                // type="submit"
                icon="pi pi-undo"
                // severity="primary"
                onClick={handleReuseVersion}
                className="border-round-lg"
                style={{
                  marginLeft: "30px",
                  backgroundColor: "#06b6d4",
                }}
                disabled={isCurrentVersion}
              />
            </div>
          </div>
        </form>
        <Toast ref={toast} />
      </div>
    </>
  );
};
export default EditDatabaseToDb;
