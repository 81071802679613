import { createAxiosInstance as myAxios } from "../../services/Helper";
const axiosInstance = await myAxios();
// console.log("selected view as" + selectedViewAs);
export const jobUpdateService = async (
  selectedViewAs,
  idCode,
  contextFormData,
  encryptedData
) => {
  if (selectedViewAs === "DEV") {
    await axiosInstance.put(
      `${idCode}/update/${contextFormData.ruleId}`,
      encryptedData
    );
  } else {
    await axiosInstance.put(
      `${selectedViewAs.toLowerCase()}/${idCode}/update/${
        contextFormData.ruleId
      }`,
      encryptedData
    );
  }
};

export const apiEndpointService = async (apiEndpoint, encryptedData) => {
  await axiosInstance.post(apiEndpoint, encryptedData);
};
