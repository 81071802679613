import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const RuleListAPI = async () => {
  const company  = localStorage.getItem('company')
  let ruleList = await axiosInstance
    .get(`/mtm/rulelist?company=${company}`)
    .then((res) => res)
    .then((finalRes) => finalRes)
    .catch((e) => console.log(e));
  return ruleList;
};
