function Validation_Login(value) {
    const errors = {};
// console.log("inside validation login")
    if(!value.email)
        errors.email = "EmailId is required"

    if(!value.password) 
        errors.password = "Password is required"
    // console.log('Errors Length:', Object.keys(errors).length);
    return errors;
}
export default Validation_Login;