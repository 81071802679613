import React from "react";
import PgpSecretKeyTable from "./PgpTable";

export default function PgpSecret() {
  return (
    <div>
      <PgpSecretKeyTable />
    </div>
  );
}
