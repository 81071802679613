import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const fetchUserService = async (company) => {
  const response = await axiosInstance.get(
    `users/get-company-users?company=${company}`
  );
  return response;
};

export const deleteUserService = async (rowData) => {
  const response = await axiosInstance.delete(
    `/admin/delete/user?email=${rowData.email}`
  );
  return response;
};
