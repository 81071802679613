import React, { useRef, useState } from "react";
import "./LoginSignup.css";
import { Form } from "reactstrap";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import { checkEmptyField } from "../../common/handleEmptyField";
import { useEffect } from "react";
import { updatePasswordService } from "../../API Services/LoginSignupServices/ChangepassService";

const Changepass = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  let email = localStorage.getItem("otpemail");
  const [loading, setLoading] = useState(false);

  let [passwordData, setPasswordData] = useState({
    password: "",
    repassword: "",
  });
  useEffect(() => {
    if (!location.state?.fromVerify) {
      navigate("/login");
    }
  }, [location, navigate]);

  // handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(e.target.value , e.target.name)
    setPasswordData({ ...passwordData, [name]: value });
    // setError({});
  };

  const [error, setError] = useState(false);
  // Handle confirm button click
  const handleConfirm = async (e) => {
    e.preventDefault();
    let emptyFieldError = checkEmptyField(passwordData);
    if (Object.keys(emptyFieldError).length !== 0) {
      setError(emptyFieldError);
      return;
    }
    // console.log(passwordData.password[0] , passwordData.repassword[0]  , passwordData.password[0] == passwordData.repassword[0])

    if (passwordData.password !== passwordData.repassword) {
      toast.current.show({
        severity: "error",
        summary: "Password Check",
        detail: "Password and Re-enter Password Should Match",
        life: 1000,
      });

      return;
    }

    setLoading(true);
    try {
      // let resp = await axiosInstance
      //   .post(
      //     `users/update/password?email=${email}&newPassword=${passwordData.password}`
      //   )
      //   .then((res) => res.data);
      //  console.log(resp)

      let resp = await updatePasswordService(email, passwordData);
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Password Changed",
          detail: "Password has been successfully changed.",
          life: 1000,
        });

        localStorage.removeItem("otpemail");
        // Redirect to the login page after showing the toast message
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay should match the toast duration
      }
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error While Changing password",
        detail: "please try again later",
        life: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
        <div className="w-12 md:w-4 lg:w-4 m-auto">
          <div className="flex justify-content-center w-full">
            <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
              <Form>
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <img
                    src={images.logo}
                    alt="logo"
                    className="login-logo m-auto"
                  />
                  <span className="text-2xl font-bold my-3 text-center w-full">
                    Change Password
                  </span>

                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <Password
                        id="password"
                        name="password"
                        value={passwordData.password}
                        onChange={handleChange}
                        feedback={false}
                        toggleMask
                        className="p-inputtext-lg border-round-lg w-full"
                        invalid={error.password}
                      />
                      <label htmlFor="password">New Password</label>
                    </FloatLabel>
                  </div>
                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <Password
                        id="repassword"
                        name="repassword"
                        value={passwordData.repassword}
                        feedback={false}
                        onChange={handleChange}
                        toggleMask
                        className="p-inputtext-lg border-round-lg w-full"
                        invalid={error.repassword}
                      />
                      <label htmlFor="repassword">Re-enter New Password</label>
                    </FloatLabel>
                  </div>

                  <div className="flex flex-column align-items-stretch justify-content-start gap-3 w-full">
                    <Button
                      label="Confirm Password"
                      type="button"
                      severity="primary"
                      onClick={handleConfirm}
                      className="border-round-lg"
                      loading={loading}
                    />
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepass;
