import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const migrateJobdtm = async (selectedTransferDept, encryptedData) => {
  try {
    // const response = await axiosInstance.post(
    //   `/moveto${selectedTransferDept.name.toLowerCase()}/dtd`,
    //   objectData?.data
    // );
    const response = await axiosInstance.post(
      `/moveto${selectedTransferDept.name.toLowerCase()}/dtm`,
      encryptedData?.data
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchVersionDataApidtm = async (
  viewRole,
  formData,
  //   selectedVersion
  e
) => {
  try {
    // let data;
    // if (viewRole === "DEV") {
    //   const resp = await axiosInstance.get(
    //     `dtd/sch/getVersion?ruleId=${formData.ruleId}&version=${selectedVersion}`
    //   );
    //   data = resp.data;
    // } else {
    //   const resp = await axiosInstance.get(
    //     `${viewRole.toLowerCase()}/dtd/sch/getVersion?ruleId=${
    //       formData.ruleId
    //     }&version=${selectedVersion}`
    //   );
    //   data = resp.data;
    // }
    let data;
    if (viewRole === "DEV") {
      const resp = await axiosInstance.get(
        `dtm/sch/getVersion?ruleId=${formData.ruleId}&version=${e.value}`
      );
      data = resp.data;
    } else {
      const resp = await axiosInstance.get(
        `${viewRole.toLowerCase()}/dtm/sch/getVersion?ruleId=${
          formData.ruleId
        }&version=${e.value}`
      );
      data = resp.data;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const reuseVersionApidtm = async (viewRole, encryptedData) => {
  try {
    // if (viewRole === "DEV") {
    //   await axiosInstance.post(`dtd/sch/setVersion`, formData);
    // } else {
    //   await axiosInstance.post(
    //     `${viewRole.toLowerCase()}/dtd/sch/setVersion`,
    //     formData
    //   );
    // }
    if (viewRole === "DEV") {
      await axiosInstance.post(`dtm/sch/setVersion`, encryptedData);
    } else {
      await axiosInstance.post(
        `${viewRole.toLowerCase()}/dtm/sch/setVersion`,
        encryptedData
      );
    }
  } catch (error) {
    throw error;
  }
};
