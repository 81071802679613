import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const migrateJobValidation = async (filteredOptions, ruleId) => {
  try {
    const response = await axiosInstance.post(
      `/moveto${filteredOptions[0].code}validation?id=${ruleId}&move="moving"&approve="pending"`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const migrateJobdtd = async (selectedTransferDept, encryptedData) => {
  try {
    const response = await axiosInstance.post(
      `/moveto${selectedTransferDept.name.toLowerCase()}/dtd`,
      encryptedData?.data
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchVersionDataApidtd = async (
  viewRole,
  formData,
  selectedVersion
) => {
  try {
    let data;
    if (viewRole === "DEV") {
      const resp = await axiosInstance.get(
        `dtd/sch/getVersion?ruleId=${formData.ruleId}&version=${selectedVersion}`
      );
      data = resp.data;
    } else {
      const resp = await axiosInstance.get(
        `${viewRole.toLowerCase()}/dtd/sch/getVersion?ruleId=${
          formData.ruleId
        }&version=${selectedVersion}`
      );
      data = resp.data;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const reuseVersionApidtd = async (viewRole, encryptedData) => {
  try {
    if (viewRole === "DEV") {
      await axiosInstance.post(`dtd/sch/setVersion`, encryptedData);
    } else {
      await axiosInstance.post(
        `${viewRole.toLowerCase()}/dtd/sch/setVersion`,
        encryptedData
      );
    }
  } catch (error) {
    throw error;
  }
};
