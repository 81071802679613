import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const fetchPgpService = async (company, type) => {
  const response = await axiosInstance
    .get(`/pgp/secret/get/pgp/all?company=${company}&type=${type}`)
    .then((resp) => resp);
  return response;
};

export const deletePgpService = async (name) => {
  const response = await axiosInstance.delete(
    `/pgp/secret/delete?secretName=${name}`
  );
  return response;
};

export const updatePgpService = async (pgpData) => {
  const response = await axiosInstance.put("/pgp/secret/update", pgpData);
  return response;
};
