import React, { useState, useRef, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { ImKey2 } from "react-icons/im";
import "primeicons/primeicons.css";
import "./VaultSecret.css";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import {
  checkEmptyField,
  checkEmptyFieldForVault,
} from "../common/handleEmptyField";
import {
  DBCreate,
  SFTPCreate,
} from "../API Services/VaultSecrets/CreateSecretsService";
import EncryptDataComponent from "../Encrypt API/Encrypt";

export default function CreateSecret() {
  const { handleEncryptedRequest } = EncryptDataComponent();

  const [sftpVisible, setSftpVisible] = useState(false);
  const [dbVisible, setDbVisible] = useState(false);
  const [pgpVisible, setPgpVisible] = useState(false);
  const [sftpError, setSftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const [pgpError, setPgpError] = useState([]);
  const navigate = useNavigate();

  //Only for Admin
  const [isAdmin, setIsAdmin] = useState(false);

  // create pgp secret data
  const [pgpValue, setPgpValue] = useState({
    name: "",
    comapnay: localStorage.getItem("company") || "",
    type: "pgp",
    // secrets: {
    publicKey: "",
    privateKey: "",
    password: "",
    // },
  });

  //env options
  const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

  useEffect(() => {
    if (localStorage.getItem("userRole")?.includes("ADMIN")) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    setSftpValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      host_dev: "",
      port_dev: "",
      username_dev: "",
      password_dev: "",
      host_qa: "",
      port_qa: "",
      username_qa: "",
      password_qa: "",
      host_prod: "",
      port_prod: "",
      username_prod: "",
      password_prod: "",
      type: "sftp",
    });
    setDbValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      type: "db",
      dbUrl_dev: "",
      username_dev: "",
      password_dev: "",
      dbUrl_qa: "",
      username_qa: "",
      password_qa: "",
      dbUrl_prod: "",
      username_prod: "",
      password_prod: "",
    });

    setPgpValue({
      name: "",
      comapnay: localStorage.getItem("company") || "",
      type: "pgp",
      // secrets: {
      publicKey: "",
      privateKey: "",
      password: "",
      // },
    });
  }, [sftpVisible, dbVisible, pgpVisible]);

  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",
    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",
    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
    type: "sftp",
  });

  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",
    dbUrl_dev: "",
    username_dev: "",
    password_dev: "",
    dbUrl_qa: "",
    username_qa: "",
    password_qa: "",
    dbUrl_prod: "",
    username_prod: "",
    password_prod: "",
  });

  const toast = useRef(null);

  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });
    setSftpError({}); // Clear error message on input change
  };

  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
    });
    setDbError({}); // Clear error message on input change
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };

  const handlePgpInputChange = (e) => {
    const { name, value } = e.target;
    setPgpValue((prev) => ({ ...prev, [name]: value }));
    setPgpError([]);
  };

  const handlePgpCreate = async (e) => {
    e.preventDefault();
    console.log(pgpValue, " is the pgp value ");

    const pgpData = {
      name: pgpValue.name,
      company: localStorage.getItem("company") || "",
      type: "pgp",
      secret: {
        publicKey: pgpValue.publicKey,
        privateKey: pgpValue.privateKey,
        passphrase: pgpValue.password,
      },
    };

    console.log("data prepared for the pgp creation", pgpData);

    const errors = Object.entries(pgpValue)
      .filter(([key, value]) => value === "") // Filter keys where value is an empty string
      .map(([key, value]) => key);

    setPgpError(errors);
    console.log(errors, " is the error ");
    if (errors.length > 0) {
      showToast("error", "error", "please fill the all details ");
      return;
    }

    // call the create pgp here

    const axiosInstance = await myAxios();
    const response = await axiosInstance
      .post(`/pgp/secret/create`, pgpData)
      .then((resp) => resp.data)
      .catch((e) => e.response.data);
    console.log(response);

    if (response.status === 201) {
      showToast("success", "Success", "Secrets created successfully!");
      setPgpVisible(false);
    } else if (response.status === 409) {
      showToast("warn", "Conflict", "Secret Already Exist ");
    } else if (response.status === 500) {
      showToast("error", "Error", "Internal Server Error ");
      setPgpVisible(false);
    }

    setPgpValue({
      name: "",
      comapnay: localStorage.getItem("company") || "",
      type: "pgp",
      // secrets: {
      publicKey: "",
      privateKey: "",
      password: "",
      // },
    });
    // setPgpVisible(false);
  };

  // const handleSFTPCreate = () => {
  //   console.log(sftpValue);
  // };
  const handleSFTPCreate = async (e) => {
    e.preventDefault();

    const requestData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    // console.log("Request Data", requestData);

    // const axiosInstance = await myAxios();
    let emptyErrors = checkEmptyFieldForVault(requestData);
    if (requestData.name === "") emptyErrors.secretName = `name can't be empty`;
    if (Object.keys(emptyErrors).length !== 0) {
      setSftpError(emptyErrors);
      return;
    }
    try {
      // const response = await axiosInstance
      //   .post("/secrets/create", requestData)
      //   .then((res) => res.data)

      //   .catch((e) => e.response);
      // console.log("Request Data", response);
      const encryptedData = await handleEncryptedRequest(requestData);

      const response = await SFTPCreate(encryptedData);
      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      } else if (response.status === 409) {
        showToast("warn", "warn", "Secrets name already exist");
        setSftpVisible(true);
        return;
      }

      // Reset state after success
      setSftpVisible(false); // Close dialog
      setSftpValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        host_dev: "",
        port_dev: "",
        username_dev: "",
        password_dev: "",
        host_qa: "",
        port_qa: "",
        username_qa: "",
        password_qa: "",
        host_prod: "",
        port_prod: "",
        username_prod: "",
        password_prod: "",
        type: "sftp",
      });
    } catch (error) {
      console.log("error : ", error);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
  };

  const handleDBCreate = async (e) => {
    e.preventDefault();

    const requestData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dbUrl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dbUrl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };
    let emptyErrors = checkEmptyFieldForVault(requestData);
    if (requestData.name === "") emptyErrors.secretName = `name can't be empty`;
    if (Object.keys(emptyErrors).length !== 0) {
      console.log("this is the db error", emptyErrors);

      setDbError(emptyErrors);
      return;
    }

    // const axiosInstance = await myAxios();

    try {
      // const response = await axiosInstance
      //   .post("/secrets/create", requestData)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);
      const encryptedData = await handleEncryptedRequest(requestData);

      const response = await DBCreate(encryptedData);
      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      } else if (response.status === 409) {
        showToast("warn", "warn", "Secrets name already exist!");
        setDbVisible(true); // Close the dialog on success
      }
      setDbVisible(false); // Close the dialog on success
      // Reset form values after successful submission
      setDbValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        type: "db",
        dbUrl_dev: "",
        username_dev: "",
        password_dev: "",
        dbUrl_qa: "",
        username_qa: "",
        password_qa: "",
        dbUrl_prod: "",
        username_prod: "",
        password_prod: "",
      });
    } catch (error) {
      console.log(error.message);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
    // console.log(dbValue);
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleSFTPCreate}
        autoFocus
      />
    </div>
  );
  const pgpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setPgpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handlePgpCreate}
        autoFocus
      />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleDBCreate}
        autoFocus
      />
    </div>
  );

  const renderMandatoryFieldMessage = (value, error) => {
    if (error) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }
    return null;
  };

  return (
    <>
      {!isAdmin && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>You don't have access to this page please reach to Admin.</h1>
        </div>
      )}

      {isAdmin && (
        <div>
          <Toast ref={toast} />
          <div className="card" style={{ width: "100%" }}>
            <h1 className="Header">Vault Setup </h1>
            <Divider />
          </div>
          <div className="card1">
            <Card title="Create Secret">
              <div style={{ marginTop: "-20px" }}>
                <p>Select the type of secret you want to create</p>
              </div>

              <div className="card flex flex-wrap justify-content-start">
                <div
                  className="SFTPSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setSftpVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-server"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>SFTP Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage SFTP Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>

                <div
                  className="DBSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setDbVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-database"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>Database Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage Database Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>

                <div
                  className="pgpSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setPgpVisible(true)}
                >
                  <div>
                    <i>
                      <ImKey2 size={30} />
                    </i>
                    {/* <i
                      className="pi pi-database"
                      style={{ fontSize: "30px" }}
                    ></i> */}
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>PGP Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely Encrypt and Decrypt Your File
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            <Card
              style={{
                marginTop: "30px",
                background: " rgba(88, 219, 255, 0.2)",
                width: "70%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className="pi pi-lock"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                ></i>
                <h4 style={{ margin: 0 }}>Secure Storage</h4>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "30px",
                }}
              >
                <p>
                  All secrets are encrypted using industry-standard algorithms
                  and stored Securely. Only authorized users with appropriate
                  permissions can access these secrets
                </p>
              </div>
            </Card>
          </div>

          {/* SFTP Secrets Dialog */}
          <Dialog
            header="SFTP Secret Details"
            visible={sftpVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setSftpVisible(false)}
            footer={sftpFooterContent}
          >
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpName">Name of Secrets</label>
                <div>
                  {/* {renderMandatoryFieldMessage(
                    sftpValue.secretName,
                    sftpError.secretName
                  )} */}
                </div>
                <InputText
                  id="sftpName"
                  name="secretName"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  invalid={sftpError.secretName}
                />
              </div>

              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for PROD */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>

                  <InputText
                    id="env"
                    name="env"
                    value={"DEV"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="host_dev">SFTP Host</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.host_dev,
                      sftpError.host_dev
                    )} */}
                  </div>
                  <InputText
                    id="host_dev"
                    name="host_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.host_dev}
                  />
                  <label htmlFor="sftpPort_dev">Port</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.port_dev,
                      sftpError.port_dev
                    )} */}
                  </div>
                  <InputText
                    id="sftpPort_dev"
                    name="port_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.port_dev}
                  />
                  <label htmlFor="sftpUserName_dev">Username</label>
                  {/* <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.username_dev,
                      sftpError.username_dev
                    )}
                  </div> */}
                  <InputText
                    id="sftpUserName_dev"
                    name="username_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.username_dev}
                  />
                  <label htmlFor="sftpPassword_dev">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.password_dev,
                      sftpError.password_dev
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_dev"
                    name="password_dev"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                    invalid={sftpError.password_dev}
                  />
                </div>

                {/* Column 2 for DEV */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>
                  <div>
                    {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                  </div>
                  <InputText
                    id="env"
                    name="env"
                    value={"QA"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="host_qa">SFTP Host</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.host_qa,
                      sftpError.host_qa
                    )} */}
                  </div>
                  <InputText
                    id="host_qa"
                    name="host_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.host_qa}
                  />
                  <label htmlFor="sftpPort_qa">Port</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.port_qa,
                      sftpError.port_qa
                    )} */}
                  </div>
                  <InputText
                    id="sftpPort_qa"
                    name="port_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.port_qa}
                  />
                  <label htmlFor="sftpUserName_qa">Username</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.username_qa,
                      sftpError.username_qa
                    )} */}
                  </div>
                  <InputText
                    id="sftpUserName_qa"
                    name="username_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.username_qa}
                  />
                  <label htmlFor="sftpPassword_qa">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.password_qa,
                      sftpError.password_qa
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_qa"
                    name="password_qa"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                    invalid={sftpError.password_qa}
                  />
                </div>

                {/* Column 3 for QA */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>
                  {/* <div>
                    {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                  </div> */}
                  <InputText
                    id="env"
                    name="env"
                    value={"PROD"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                    invalid={sftpError.env}
                  />
                  <label htmlFor="host_prod">SFTP Host</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.host_prod,
                      sftpError.host_prod
                    )} */}
                  </div>
                  <InputText
                    id="host_prod"
                    name="host_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.host_prod}
                  />
                  <label htmlFor="sftpPort_prod">Port</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.port_prod,
                      sftpError.port_prod
                    )} */}
                  </div>
                  <InputText
                    id="sftpPort_prod"
                    name="port_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.port_prod}
                  />
                  <label htmlFor="sftpUserName_prod">Username</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.username_prod,
                      sftpError.username_prod
                    )} */}
                  </div>
                  <InputText
                    id="sftpUserName_prod"
                    name="username_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={sftpError.username_prod}
                  />
                  <label htmlFor="sftpPassword_prod">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      sftpValue.password_prod,
                      sftpError.password_prod
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_prod"
                    name="password_prod"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                    invalid={sftpError.password_prod}
                  />
                </div>
              </div>
            </div>
          </Dialog>

          {/* Database Secrets Dialog */}
          <Dialog
            header="Database Secret Details"
            visible={dbVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setDbVisible(false)}
            footer={dbFooterContent}
          >
            {/* <div className="flex flex-column align-items-start"> */}
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbName">Secret Name</label>
                <div>
                  {/* {renderMandatoryFieldMessage(
                    dbValue.secretName,
                    dbError.secretName
                  )} */}
                </div>
                <InputText
                  id="dbName"
                  name="secretName"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  invalid={dbError.secretName}
                />
              </div>

              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for PROD */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"DEV"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_dev">Database URL</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.dbUrl_dev,
                      dbError.dbUrl_dev
                    )} */}
                  </div>
                  <InputText
                    id="dbUrl_dev"
                    name="dbUrl_dev"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.dburl_dev}
                  />
                  <label htmlFor="dbUserName_dev">Username</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.username_dev,
                      dbError.username_dev
                    )} */}
                  </div>
                  <InputText
                    id="dbUserName_dev"
                    name="username_dev"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.username_dev}
                  />
                  <label htmlFor="dbPassword_dev">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.password_dev,
                      dbError.password_dev
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_dev"
                    name="password_dev"
                    type="password"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                    invalid={dbError.password_dev}
                  />
                </div>
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"QA"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_qa">Database URL</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.dbUrl_qa,
                      dbError.dbUrl_qa
                    )} */}
                  </div>
                  <InputText
                    id="dbUrl_qa"
                    name="dbUrl_qa"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.dburl_qa}
                  />
                  <label htmlFor="dbUserName_qa">Username</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.username_qa,
                      dbError.username_qa
                    )} */}
                  </div>
                  <InputText
                    id="dbUserName_qa"
                    name="username_qa"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.username_qa}
                  />
                  <label htmlFor="dbPassword_qa">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.password_qa,
                      dbError.password_qa
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_qa"
                    name="password_qa"
                    type="password_qa"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                    invalid={dbError.password_qa}
                  />
                </div>
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"PROD"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_prod">Database URL</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.dbUrl_prod,
                      dbError.dbUrl_prod
                    )} */}
                  </div>
                  <InputText
                    id="dbUrl_prod"
                    name="dbUrl_prod"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.dburl_prod}
                  />
                  <label htmlFor="dbUserName_prod">Username</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.username_prod,
                      dbError.username_prod
                    )} */}
                  </div>
                  <InputText
                    id="dbUserName_prod"
                    name="username_prod"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    invalid={dbError.username_prod}
                  />
                  <label htmlFor="dbPassword_prod">Password</label>
                  <div>
                    {/* {renderMandatoryFieldMessage(
                      dbValue.password_prod,
                      dbError.password_prod
                    )} */}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_prod"
                    name="password_prod"
                    type="password_prod"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                    invalid={dbError.password_prod}
                  />
                </div>
              </div>
            </div>
          </Dialog>

          {/* Pgp Secret Dialog */}

          <Dialog
            header="PGP Secret Details"
            visible={pgpVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setPgpVisible(false)}
            footer={pgpFooterContent}
          >
            {/* <div className="flex flex-column align-items-start"> */}
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for pgp secrets */}
                <div style={{ flex: "1" }} className="col-4">
                  <label style={{ visibility: "hidden" }}>Secret Name </label>
                  <div className="my-2 ">
                    <FloatLabel>
                      <label htmlFor="pgpname">Secret Name</label>
                      <InputText
                        id="pgpname"
                        className="w-full"
                        name="name"
                        placeholder="Enter Pgp Secret Name "
                        value={pgpValue.name}
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("name")}
                      />
                    </FloatLabel>
                  </div>

                  <div className="my-4  ">
                    <FloatLabel>
                      <label htmlFor="pgpPublickey">Public Key :</label>
                      <InputTextarea
                        value={pgpValue.publicKey}
                        name="publicKey"
                        id="pgpPublickey"
                        cols={40}
                        rows={15}
                        placeholder="Enter pgp public key here"
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("publicKey")}
                      />
                    </FloatLabel>
                  </div>
                </div>

                {/* Column 2 for the pgp secret */}
                <div style={{ flex: "1" }} className="col-4">
                  <div className="my-2">
                    <label htmlFor="pgpPassword">Password</label>
                    {/* <FloatLabel> */}
                    <Password
                      className="w-full"
                      id="pgpPassword"
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      toggleMask
                      onChange={handlePgpInputChange}
                      required
                      invalid={pgpError.includes("password")}
                    />
                    {/* </FloatLabel> */}
                  </div>
                  <div className="my-4  ">
                    <FloatLabel>
                      <label htmlFor="pgpPrivatekey">Private Key :</label>
                      <InputTextarea
                        value={pgpValue.privateKey}
                        name="privateKey"
                        id="pgpPrivateckey"
                        cols={40}
                        rows={15}
                        placeholder="Enter pgp private key here"
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("privateKey")}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
