import React, { useState, useEffect } from "react";
// import axios from "axios";
import JSEncrypt from "jsencrypt";
import { createAxiosInstance as myAxios } from "../services/Helper";

// Generate AES key with Web Crypto API
const generateAESKey = async () => {
  const key = await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 128,
    },
    true,
    ["encrypt", "decrypt"]
  );
  return key;
};

// Encrypt data with AES-GCM mode
const encryptWithAES = async (data, aesKey) => {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // 12-byte IV for GCM
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);
  const encrypted = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    aesKey,
    encodedData
  );
  return {
    encryptedData: btoa(String.fromCharCode(...new Uint8Array(encrypted))),
    iv: btoa(String.fromCharCode(...iv)),
  };
};

// Convert AES key to Base64 for RSA encryption
const getAesKeyAsString = async (aesKey) => {
  const exported = await crypto.subtle.exportKey("raw", aesKey);
  return btoa(String.fromCharCode(...new Uint8Array(exported)));
};

// RSA encrypt function using JSEncrypt
const encryptDataWithRSA = (data, publicKey) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(data);
};

const EncryptDataComponent = () => {
  const [publicKey, setPublicKey] = useState("");

  useEffect(() => {
    const fetchPublicKey = async () => {
      try {
        const axiosInstance = await myAxios();
        const axiosresponse = await axiosInstance
          .get("/encryption/publicKey")
          .then((res) => res.data)
          .catch((e) => console.log(e));
        // console.log(axiosresponse, "axiosresponse");
        console.log("in try block");
        // const response = await axios.get(
        //   "http://localhost:8080/api/encryption/publicKey"
        // );
        setPublicKey(axiosresponse);
      } catch (error) {
        console.error("Error fetching public key", error);
      }
    };
    fetchPublicKey();
  }, []);
  //   console.log(publicKey, "public key");
  const handleEncryptedRequest = async (data, endpoint) => {
    if (!publicKey) {
      console.error("Public key is not available");
      return;
    }

    const dataJson = JSON.stringify(data);

    const aesKey = await generateAESKey();
    const aesKeyBase64 = await getAesKeyAsString(aesKey);

    const encryptedAesKey = encryptDataWithRSA(aesKeyBase64, publicKey);

    const encryptedDataWithAES = await encryptWithAES(dataJson, aesKey);
    const iv = encryptedDataWithAES.iv;
    const encryptedData = encryptedDataWithAES.encryptedData;

    const dataToSendToBackend = {
      encryptedAesKey: encryptDataWithRSA(aesKeyBase64, publicKey),
      encryptedData: encryptedDataWithAES.encryptedData,
      iv: encryptedDataWithAES.iv,
    };

    return dataToSendToBackend;
  };

  return { handleEncryptedRequest };
};

export default EncryptDataComponent;
