import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const forgotPasswordService = async (values) => {
  let resp = await axiosInstance.post(
    `users/forgot/password?email=${values.email}`
  );
  return resp;
};
