import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { Link, useLocation } from "react-router-dom";
import { fetchJobData } from "../../API Services/HistoryServices/JobDetailsService";
import { SiJira } from "react-icons/si";

export default function JobDetails({ ruleId }) {
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { viewRole } = location.state || {};

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Replace the endpoint with the correct one that uses the ruleId
  //       const axiosInstance = await myAxios();
  //       if (viewRole === "DEV") {
  //         const response = await axiosInstance.get(`mtm/rid/${ruleId}`);
  //         setJobData(response.data);
  //       } else {
  //         const response = await axiosInstance.get(
  //           `${viewRole.toLowerCase()}/mtm/rid/${ruleId}`
  //         );
  //         setJobData(response.data);
  //       }
  //       // console.log("API Response:", response.data);
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (ruleId) {
  //     fetchData();
  //   }
  // }, [ruleId]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await fetchJobData(ruleId, viewRole);
        setJobData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      getData();
    }
  }, [ruleId, viewRole]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!jobData) {
    return <div>No job data available</div>;
  }

  // Status mapping
  const statusMap = {
    0: "Not started",
    1: "Started",
    2: "Paused",
    3: "Resumed",
    4: "Deleted",
    5: "Stopped",
  };

  // Create job details with status mapped
  const jobDetails = [
    { field: "Job Name", value: jobData.fileName || "N/A" },
    { field: "Job Description", value: jobData.jobDescription || "N/A" },
    { field: "Created Time", value: jobData.createdTime || "N/A" },
    { field: "Modified Time", value: jobData.modifiedTime || "N/A" },
    { field: "RunTime", value: jobData.runTime || "N/A" },
    { field: "Status", value: statusMap[jobData.active] || "N/A" }, // Map the status value
    { field: "Schedule By", value: jobData.createdby || "N/A" },
    { field: "Updated By", value: jobData.updatedby || "N/A" },
    { field: "jira URL", value: jobData.jiraUrl || "N/A",tag: "anchor" },
  ];

  const fieldBodyTemplate = (rowData) => {
    return (
      <span style={{ display: "block", padding: "0.5rem" }}>
        {rowData.field}
      </span>
    );
  };
  const getLastSegment = (url) => {
    if (!url) return ""; // Handle null or undefined URLs
    const segments = url.split("/"); // Split the URL by "/"
    return segments.pop(); // Get the last segment
  };

  const valueBodyTemplate = (rowData) => {
    // console.log("this isthe row data "+ jobData.jiraUrl)
    return (
      rowData.tag!=="anchor"?
      <span style={{ display: "block", padding: "0.5rem" }}>
        {rowData.value}
      </span>:
       <Link to={jobData.jiraUrl}><span style={{ display: "block", padding: "0.5rem" }}>
        <SiJira color="blue"/> 
        [{getLastSegment(rowData.value)}] 
     </span></Link>
    );
  };

  return (
    <div className="card" style={{ marginTop: "2px" }}>
      <DataTable
        value={jobDetails}
        header="Job Details"
        tableStyle={{ minWidth: "30rem" }}
      >
        <Column
          field="field"
          header="Field"
          body={fieldBodyTemplate}
          style={{ backgroundColor: "#f9fafb", width: "15rem" }}
        />
        <Column field="value" header="Value" body={valueBodyTemplate} />
      </DataTable>
    </div>
  );
}
