import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const verifyOtpService = async (email, code) => {
  let resp = await axiosInstance.post(
    `users/verify/otp?email=${email}&otp=${code}`
  );
  return resp;
};

export const resendOtpService = async (email) => {
  let resp = await axiosInstance.post(`users/forgot/password?email=${email}`);
  return resp;
};
