import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const fetchDatabaseService = async (
  viewRole,
  ruleId,
  targetTableName
) => {
  const response =
    viewRole === "DEV"
      ? await axiosInstance.get(
          `filess?id=${ruleId}&archivePath=${encodeURIComponent(
            targetTableName
          )}`
        )
      : await axiosInstance.get(
          `${viewRole.toLowerCase()}/filess?id=${ruleId}&archivePath=${encodeURIComponent(
            targetTableName
          )}`
        );
  return response;
};
