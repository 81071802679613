import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const updateSecret = async (requestData) => {
  try {
    const response = await axiosInstance.put("/secrets/update", requestData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateDBSecret = async (requestData) => {
  try {
    const response = await axiosInstance.put("/secrets/update", requestData);
    return response;
  } catch (err) {
    throw err;
  }
};

//This is Useeffect
export const getAllSecretsSftpAndDB = async (type) => {

  try {
    const response = await axiosInstance.get(
      `/secrets/get-secrets/cp-name?company=${
        localStorage.getItem("company") || ""
      }&type=${type}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteSecret = async (name) => {
  try {
    const response = await axiosInstance.delete(
      `/secrets/delete?secretName=${name}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};
