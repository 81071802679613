import { createAxiosInstance as myAxios } from "../services/Helper";

const axiosInstance = await myAxios();

export const TestConnectionDBService = async (encryptedData) => {
  let sftp_connect = await axiosInstance
    .post("/test/con/db", encryptedData)
    .then((res) => res.data)
    .catch((e) => e.response.data);
  return sftp_connect;
};

export const TestConnectionSFTPService = async (encryptedData) => {
  let sftp_connect = await axiosInstance
    .post("/test/con/sftp", encryptedData)
    .then((res) => res.data);
  return sftp_connect;
};

export const TestConnectionSFTP2Service = async (sftpServer1) => {
  let sftp_connect = await axiosInstance
    .post("/test/con/sftp2", sftpServer1)
    .then((res) => res.data);
  return sftp_connect;
};
