import { createAxiosInstance as myAxios } from "../../services/Helper";

export const fetchJobData = async (ruleId, viewRole) => {
  try {
    const axiosInstance = await myAxios();
    let response;

    if (viewRole === "DEV") {
      response = await axiosInstance.get(`mtm/rid/${ruleId}`);
    } else {
      response = await axiosInstance.get(
        `${viewRole.toLowerCase()}/mtm/rid/${ruleId}`
      );
    }

    return response.data; // Return the data if successful
  } catch (error) {
    throw error; // Throw the error if the request fails
  }
};
