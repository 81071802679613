import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const fetchRulesService = async (fetchApi) => {
  try {
    const res = await axiosInstance.get(fetchApi);
    return res.data;
  } catch (e) {
    throw e; // Rethrow error to handle it in the calling component
  }
};

export const startRuleService = async (ruleId, selectedViewRole) => {
  const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();

  try {
    const url =
      selectedViewRole.name === "DEV"
        ? `${ruleCode}/sch/start/${ruleId}`
        : `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/start/${ruleId}`;

    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const stopRuleService = async (ruleId, selectedViewRole) => {
  const ruleCode = ruleId.substring(0, 3);

  try {
    const url =
      selectedViewRole.name === "DEV"
        ? `${ruleCode}/sch/stop/${ruleId}`
        : `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/stop/${ruleId}`;

    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const pauseRuleService = async (ruleId, selectedViewRole) => {
  const ruleCode = ruleId.substring(0, 3);

  try {
    const url =
      selectedViewRole.name === "DEV"
        ? `${ruleCode}/sch/pause/${ruleId}`
        : `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/pause/${ruleId}`;

    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resumeRuleService = async (ruleId, selectedViewRole) => {
  const ruleCode = ruleId.substring(0, 3);

  try {
    const url =
      selectedViewRole.name === "DEV"
        ? `${ruleCode}/sch/resume/${ruleId}`
        : `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/resume/${ruleId}`;

    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteRuleService = async (ruleId, selectedViewRole) => {
  const ruleCode = ruleId.substring(0, 3);
  const axiosInstance = await myAxios();

  try {
    let url = "";
    if (selectedViewRole.name === "DEV") {
      url = `${ruleCode}/sch/delete/${ruleId}`;
    } else if (selectedViewRole.name === "QA") {
      url = `qa/${ruleCode}/sch/delete/${ruleId}`;
    } else if (selectedViewRole.name === "PROD") {
      url = `prod/${ruleCode}/sch/delete/${ruleId}`;
    }

    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


