import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const fetchFilesService = async (viewRole, fileForm) => {
  const response =
    viewRole === "DEV"
      ? await axiosInstance.get(
          `filess?id=${fileForm.ruleId}&archivePath=${encodeURIComponent(
            fileForm.archivePath
          )}`
        )
      : await axiosInstance.get(
          `${viewRole.toLowerCase()}/filess?id=${
            fileForm.ruleId
          }&archivePath=${encodeURIComponent(fileForm.archivePath)}`
        );
  return response;
};
