import { createAxiosInstance as myAxios } from "../services/Helper";

export const registerCompany = async (encryptedData, companyLogo) => {
  const data = new FormData();
  data.append("user", JSON.stringify(encryptedData));
  data.append("companyLogo", companyLogo);

  const axiosInstance = await myAxios(); // Assuming you have a custom Axios instance

  try {
    const response = await axiosInstance.post("/companysignup/register", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response;
  } catch (err) {
    throw err; // Re-throw the error so it can be handled in the calling function
  }
};

export const verifyOtp = async (formData, otp) => {
  const axiosInstance = await myAxios();

  try {
    const response = await axiosInstance.post(
      `/companysignup/verify?email=${formData.email}&otp=${otp}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};
