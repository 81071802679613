import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const updatePasswordService = async (email, passwordData) => {
  let resp = await axiosInstance
    .post(
      `users/update/password?email=${email}&newPassword=${passwordData.password}`
    )
    .then((res) => res.data);
  return resp;
};
