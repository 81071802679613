import React, { useEffect, useState } from "react";
import "./UserList.css";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import UserRegistration from "../../Pages/UserRegistration";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import {
  deleteUserService,
  fetchUserService,
} from "../../API Services/userComponent/UserListService";

const UserList = () => {
  const [customers, setCustomers] = useState();
  const navigate = useNavigate();
  const company = localStorage.getItem("company");
  const [isUserDelete, setUserDelete] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetchUserService(company);
        setCustomers(response.data);
        //to check the user is Admin or not
        if (localStorage.getItem("userRole").includes("ADMIN"))
          setIsAdmin(true);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          navigate("/login");
        } else {
          console.error(e);
        }
      }
    };

    fetchCustomers();
  }, [isUserDelete]);

  const confirm1 = (rowData, handleDeleteUserClick) => {
    if (rowData.email === localStorage.getItem("userId")) {
      // Show alert using Toast for "You can't delete the Admin"
      toast.current.show({
        severity: "warn",
        summary: "Delete Confirmation",
        detail: "You can't delete the Admin",
        life: 3000,
      });
    } else {
      confirmDialog({
        message: "Are you sure you want to delete this user?",
        header: "Delete Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Yes",
        rejectLabel: "No",
        accept: () => {
          handleDeleteUserClick(rowData); // Call the delete function with the specific rowData
        },
      });
    }
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    permissions: { value: null, matchMode: FilterMatchMode.IN },
    admin: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const [email, setEmail] = useState("");
  const [isRegisterSidebarVisible, setRegisterSidebarVisible] = useState(false);
  const handleEditClick = (emaild) => {
    setEmail(emaild);
    setRegisterSidebarVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    // console.log(rowData);
    return (
      <>
        <div className="flex flex-row align-items-center justify-content-center gap-2">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            aria-label="Edit"
            onClick={() => handleEditClick(rowData.email)}
          />

          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Cancel"
            onClick={() => confirm1(rowData, handleDeleteUserClick)}
          />
        </div>
      </>
    );
  };

  const handleDeleteUserClick = async (rowData) => {
    try {
      const response = await deleteUserService(rowData);
      setUserDelete(true);

      if (response.status === 401) {
        navigate("/login");
      }
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "user Delete",
          detail: "User Delete Successfully.",
          life: 3000,
        });
      }
    } catch (e) {
      console.log(e);
      setUserDelete(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete user.",
        life: 3000,
      });
    }
  };

  const header = renderHeader();

  return (
    <>
      <div className="card">
        <DataTable
          severity="primary"
          value={customers}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="row"
          loading={loading}
          globalFilterFields={["firstName", "lastName", "email", "permissions"]}
          header={header}
          emptyMessage="No users found."
        >
          <Column
            field="firstName"
            header="First Name"
            filter
            filterPlaceholder="Search"
            style={{ minWidth: "12rem" }}
          />
          <Column
            field="lastName"
            header="Last Name"
            filterField="lastName"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Search"
          />
          <Column
            field="email"
            header="Email"
            filterField="email"
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
          <Column
            field="permissions"
            header="Roles"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Search"
          />
          <Column
            header="Action"
            style={{ minWidth: "6rem" }}
            body={isAdmin ? actionBodyTemplate : ""}
          />
        </DataTable>

        <Toast ref={toast} />

        <ConfirmDialog />
      </div>

      {isRegisterSidebarVisible && (
        <UserRegistration onClose={setRegisterSidebarVisible} mail={email} />
      )}
    </>
  );
};

export default UserList;
