import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();
export const SFTPCreate = async (encryptedData) => {
  try {
    const response = await axiosInstance
      .post("/secrets/create", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response);
    return response;
  } catch (err) {
    throw err;
  }
};

export const DBCreate = async (encryptedData) => {
  try {
    const response = await axiosInstance
      .post("/secrets/create", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response);
    return response;
  } catch (err) {
    throw err;
  }
};
