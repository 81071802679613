import { createAxiosInstance as myAxios } from "../services/Helper";

const axiosInstance = await myAxios();

export const editSFTPService = async (company) => {
  const response = await axiosInstance
    .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
    .then((res) => res.data)
    .catch((e) => e.response);
  return response;
};

export const editDBService = async (company) => {
  const dbResponse = await axiosInstance
    .get(`/secrets/get-by-company/get?company=${company}&type=db`)
    .then((res) => res.data)
    .catch((e) => e.response);
  return dbResponse;
};

export const editPGPService = async (company) => {
  const responsePGP = await axiosInstance
    .get(`/pgp/secret/get/all/names?company=${company}`)
    .then((res) => res.data)
    .catch((e) => e.response);
  return responsePGP;
};
